<template>
	<div class=''>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Long Term Liabilities</Navbar>
		<div class="wrapper">
			<!-- 表格 -->
			<el-table :data="assetsTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}">
				<el-table-column prop="name" label="Long Term Liabilities">
				</el-table-column>
				<el-table-column prop="Amount" label="Amount" width="400">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.AmountShow}}</span>
						<AmountInput v-else :inputValue="scope.row.AmountShow" :currentRow="scope.row" :currentArr="assetsTableData" @updateArr="updateArr"></AmountInput>
					</template>
				</el-table-column>
				<el-table-column label="" width="40" />
			</el-table>
			<!-- 其他资产 -->
			<Navbar backgroundColor="#e8e8e8" fontSize="18px" className="flex">
				Investment property mortgage
				<i class="el-icon-plus addIcon" @click="mortgageAddRow" v-if="!disabled"></i>
			</Navbar>
			<el-table :data="mortgageTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}" :show-header="false">
				<el-table-column prop="field_key" label="Name">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.field_key}}</span>
						<el-input v-else v-model="scope.row.field_key" placeholder="Name" @input="scope.row.field_key = $util.inputReg(scope.row.field_key)"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="field_value" label="Amount" width="400">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.field_value_show}}</span>
						<AmountInputCustom v-else :inputValue="scope.row.field_value_show" :currentRow="scope.row" :currentArr="mortgageTableData" @updateArr="updateArr2"></AmountInputCustom>
					</template>
				</el-table-column>
				<el-table-column width="40">
					<template slot-scope="scope">
						<i class="el-icon-delete" @click="mortgageDeleteRow(scope.row.dynamic_field_order)" v-if="!disabled"></i>
					</template>
				</el-table-column>
			</el-table>
			<Navbar backgroundColor="#e8e8e8" fontSize="18px" className="flex">
				Others
				<i class="el-icon-plus addIcon" @click="longAddRow" v-if="!disabled"></i>
			</Navbar>
			<el-table :data="longTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}" :show-header="false">
				<el-table-column prop="field_key" label="Name">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.field_key}}</span>
						<el-input v-else v-model="scope.row.field_key" placeholder="Name" @input="scope.row.field_key = $util.inputReg(scope.row.field_key)"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="field_value" label="Amount" width="400">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.field_value_show}}</span>
						<AmountInputCustom v-else :inputValue="scope.row.field_value_show" :currentRow="scope.row" :currentArr="longTableData" @updateArr="updateArr3"></AmountInputCustom>
					</template>
				</el-table-column>
				<el-table-column width="40">
					<template slot-scope="scope">
						<i class="el-icon-delete" @click="longDeleteRow(scope.row.dynamic_field_order)" v-if="!disabled"></i>
					</template>
				</el-table-column>
			</el-table>
			<!-- 总资产 -->
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total S$<span style="color: black;">{{totalAmount}}</span></div>
		</div>
	</div>
</template>

<script>
import Navbar from "../../../../components/Navbar.vue";
import AmountInput from "../../components/amountInput.vue";
import AmountInputCustom from "../../components/amountInputCustom.vue";

export default {
	components: { Navbar, AmountInput, AmountInputCustom },
	data () {
		return {
			disabled: false,
			assetsTableData: [
				{
					Amount: '0',
					name: 'Mortgage (Residential)',
					value: 'mortgage',
				}, {
					Amount: '0',
					name: 'Car Loan',
					value: 'car_loan',
				}
			],
			OthersTableData: [],
			mortgageTableData: [],
			longTableData: []
		};
	},
	computed: {
		total_long_term_liabilities () {
			let total = 0
			this.assetsTableData.map(res => { total += parseFloat(res.Amount || 0) })
			this.mortgageTableData.map(res => {
				if (res.field_key) total += parseFloat(res.field_value)
			})
			this.longTableData.map(res => {
				if (res.field_key) total += parseFloat(res.field_value)
			})
			if (isNaN(total)) total = ''
			return (Math.round(total * 100) / 100).toFixed(10).replace(/(0+)$/g, '')
		},
		totalAmount () {
			return this.$formatMoney((Math.round(this.total_long_term_liabilities * 100) / 100).toFixed(2))
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		updateArr (arr) {
			this.$forceUpdate()
			this.assetsTableData = arr
		},
		updateArr2 (arr) {
			this.$forceUpdate()
			this.mortgageTableData = arr
		},
		updateArr3 (arr) {
			this.$forceUpdate()
			this.longTableData = arr
		},
		mortgageAddRow () {
			this.mortgageTableData.push({
				index: this.mortgageTableData.length,
				field_value_show: '',
				field_key: '',
				field_value: '',
				dynamic_field_type: 'mortgage',
				dynamic_field_order: Date.now().toString()
			})
		},
		mortgageDeleteRow (index) {
			this.mortgageTableData = this.mortgageTableData.filter(n => n.dynamic_field_order !== index)
		},
		longAddRow () {
			this.longTableData.push({
				index: this.longTableData.length,
				field_value_show: '',
				field_key: '',
				field_value: '',
				dynamic_field_type: 'long',
				dynamic_field_order: Date.now().toString()
			})
		},
		longDeleteRow (index) {
			this.longTableData = this.longTableData.filter(n => n.dynamic_field_order !== index)
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			if (!this.disabled) {
				// assetsTableData值赋予kycAllData
				this.assetsTableData.map(res => { this.kycAllData.kyc.client[this.kycClient].assets_liabilities[res.value] = res.Amount })
				// total
				this.kycAllData.kyc.client[this.kycClient].assets_liabilities.total_long_term_liabilities = this.total_long_term_liabilities
				this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
				// 自定义内容存在vuex中，在父页面点击下一步时，合并所有自定义内容,去掉key为空的
				this.mortgageTableData = this.mortgageTableData.filter(n => n.field_key !== '')
				this.longTableData = this.longTableData.filter(n => n.field_key !== '')
				this.$store.commit('SET_ASSETS_LIABILITIES', { mortgage: this.mortgageTableData })
				this.$store.commit('SET_ASSETS_LIABILITIES', { long: this.longTableData })
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		// 若有值 则赋值
		this.assetsTableData.map((key, i) => {
			key.Amount = this.kycAllData.kyc.client[this.kycClient].assets_liabilities[key.value]
			key.AmountShow = 'S$ ' + this.$formatMoney((Math.round(key.Amount * 100) / 100))
			key.index = i
		})
		this.mortgageTableData = this.$store.state.KYC.Assets_liabilities['mortgage']
		this.mortgageTableData.map((key, i) => {
			key.field_value_show = 'S$ ' + this.$formatMoney((Math.round(key.field_value * 100) / 100))
			key.index = i
		})
		this.longTableData = this.$store.state.KYC.Assets_liabilities['long']
		this.longTableData.map((key, i) => {
			key.field_value_show = 'S$ ' + this.$formatMoney((Math.round(key.field_value * 100) / 100))
			key.index = i
		})
		this.disabled = this.$router.currentRoute.fullPath.includes('View')
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
span {
	line-height: 24px;
}
</style>